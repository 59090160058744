import './faq.css';
import Navbar from '../navbar';
import Footer from '../footer';
import React, { useState } from 'react';
import { BiChevronDown } from "react-icons/bi";




function Faq() {

    
    const [collapsed, setCollapsed] = useState(true);
    
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const [collapsed2, setCollapsed2] = useState(true);
    
    const toggleCollapse2 = () => {
        setCollapsed2(!collapsed2);
    };

    const [collapsed3, setCollapsed3] = useState(true);
    
    const toggleCollapse3 = () => {
        setCollapsed3(!collapsed3);
    };

    return (
        <><Navbar />
        <div className='about-header'>
            <h1 id='about-header'>FAQ</h1>
        </div>
        <div className='faq'>
            <div className='faq-question' onClick={toggleCollapse}>
                <div>
                <h2>What can I expect from the initial session - especially with a pelvic floor evaluation?</h2>
                </div>
                <div>
                <BiChevronDown id='arrow-down' />
                </div>
                
            </div>
            <div>
                {!collapsed && (
                    <div>
                        {/* Content to be collapsed */}
                        <h3>Many patients are apprehensive about their first appointment. They don’t know what to expect so they just avoid therapy all together! The hope is to prepare you well so that you’re comfortable coming in to your first appointment. 
                        </h3>
                        <br />
                        <h3>First, we spend time discussing your medical history, perform an assessment of your posture, and how you move with basic tasks. We check out your joints, what is the strength and flexibility of certain muscles and how your tissue feels from head to toe. We will specifically concentrate on areas that can impact your pelvic floor which could include your neck or even your ankles! 
                        </h3>
                        <br />
                        <h3>Lastly, we perform an internal pelvic floor exam with your consent. We will only do this if you feel comfortable during your initial visit. This exam is to test certain muscles of your pelvic floor, see what the strength, flexibility, coordination is like and also if you can relax your pelvic floor. We will guide you through this whole process and make sure you are comfortable. This is very important as it gives us a specific treatment plan individualized for you. We will spend the last part of the session discussing treatment strategies which can include exercise, manual work, nutrition, strategies and tactics to break habits and form good ones.</h3>
                        <br />
                    </div>
                )}
            </div>
            <div className='faq-question' onClick={toggleCollapse2}>
                <div>
                <h2>Do you take insurance?</h2>
                </div>
                <div>
                <BiChevronDown id='arrow-down'/>
                </div>
            </div>
            <div>
                {!collapsed2 && (
                    <div>
                        {/* Content to be collapsed */}
                        <h3>Sozo Sports Physical Therapy is out of network. The reason for this is so we can directly determine how to meet your goals more efficiently and not have insurance dictate your plan of care. Often times you will have unnecessary visits due to insurance policies or your sessions will be spent on services that are not necessarily an appropriate use of your time or have limited evidence/efficacy. This increases your cost of therapy sessions. 
                        </h3>
                        <br />
                        <h3>At Sozo Sports Physical Therapy we charge less than what practices and hospitals bill for each session. You are able to give your insurance an invoice at the end of our time for reimbursement. The location, the hours of operation, and availability to connect with the therapist 24/7 allows patient needs to be met! Please contact us for our rates. </h3>
                        <br />
                    </div>
                )}
            </div>
            <div className='faq-question' onClick={toggleCollapse3}>
                <div>
                <h2>Do I need a referral for physical therapy?</h2>
                </div>
                <div>
                <BiChevronDown id='arrow-down'/>
                </div>
            </div>
            <div>
                {!collapsed3 && (
                    <div>
                        {/* Content to be collapsed */}
                        <h3>Under the Oklahoma Physical Therapy Practice Act, a PT can evaluate and treat a patient without a referral from a licensed health care practitioner for a period not to exceed 30 days. After 30 days, we will need a referral to the agreement of our plan of care. It is recommended to see your primary care physician first so they can screen out other medical conditions and appropriately refer you to therapy. </h3>
                        <br />
                    </div>
                )}
            </div>
        </div>
        <Footer /></>
    )
}


export default Faq;