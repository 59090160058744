import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import Nav from './nav';
import ScrollToTop from './ScrollToTop';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAZZwCl9KmXg_vzmkwBOrxGA5WVrrBrAu8",
  authDomain: "sozo-1357.firebaseapp.com",
  projectId: "sozo-1357",
  storageBucket: "sozo-1357.appspot.com",
  messagingSenderId: "638803004043",
  appId: "1:638803004043:web:5cab8c59fdbfe6ad9fe28e",
  measurementId: "G-RJD78MX7KT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <><React.StrictMode>
      <BrowserRouter>
        <ScrollToTop/>
        <Nav />
      </BrowserRouter>
  </React.StrictMode>
  </>
);

