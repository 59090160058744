import React from 'react';
import "./Img_Carousel.css";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useState } from 'react';

export const Img_Carousel = ({data}) => {
    const [review, setReview] = useState(0);

    const nextReview = () => {
        setReview(review === data.reviews.length - 1 ? 0 : review + 1);
    }

    const prevReview = () => {
        setReview(review === 0 ? data.reviews.length - 1 : review - 1);
    }
    
  return (
    <div className='review-container'>
        <div className='c-reviews'>
            {data.reviews.map((item, idx) => {
                return <h2 key={idx} className={review === idx ? "c-review-top" : "c-review-top-hidden"}>{item.person}</h2>
            })}
            <div className='c-reviews-middle'>
                
                {data.reviews.map((item, idx) => {
                    return <h3 key={idx} className={review === idx ? "c-review" : "c-review-hidden"}>{item.review}</h3>
                })}
                
            </div>
            <div className='arrows'>
                <AiOutlineArrowLeft id="arrow-left" onClick={prevReview}/>
                <AiOutlineArrowRight id="arrow-left" onClick={nextReview}/>
            </div>
            <span className='indicators'>
                {data.reviews.map((_, idx) => {
                    return <button key={idx} onClick={() => setReview(idx)} className={review === idx ? 'indicator' : 'indicator-inactive'}></button>
                })}
            </span>
        </div>
    </div>

    
  )
}

export default Img_Carousel;
