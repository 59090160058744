import Navbar from '../navbar';
import './about.css';
import Footer from '../footer';
import Tricia from '../images/tricia.webp';

function about() {
    return (
        <><Navbar />
        <div className='about-header'>
            <h1 id='about-header'>About</h1>
        </div>
        <div className='about'>
            <div className='about-picture'>
                <h1>Dr. Tricia Wang PT, DPT, FAAOMPT</h1>
                <br />
                <img id='tricia' src={Tricia} ></img>
            </div>
            <div className='about-paragraph'>
                <h3>Tricia attended Saint Louis University where she obtained her undergraduate degree in exercise science with a minor in psychology. She continued on to receive her doctorate in physical therapy in 2011. During her time there, she graduated Magna Cum Laude and was selected as an MLK scholar and American Physical Therapy Association scholar.</h3>
                <br />
                <h3>After she started her extensive training in pelvic floor rehabilitation in Chicago, IL she went on to Austin, TX to complete a 3 year fellowship in orthopedic manual physical therapy that less than 1% of therapists have completed (Fellowship of American Academy Orthopedic Manual Physical Therapy).</h3>
                <br />
                <h3>She is skilled in joint manipulation, visceral/soft tissue mobilization, and tendon/cartilage training. She is certified in the Hesch method of manual therapy as a sacroilliac joint specialist and certified in kinesiotaping and dry needling. She has lived and taught in various countries and worked with potential Olympic athletes overseas. </h3>
                <br />
                <h3>She has always wanted to run a God-centered practice with the intent to bring healing and hope to her community. In her free time, she enjoys playing sports, rock climbing, traveling, and hanging out with her husband and kids.</h3>
                <br />
            </div>
        </div>
        <Footer /></>
    )
}


export default about;