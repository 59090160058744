import './location.css'
import Navbar from '../navbar';
import Footer from '../footer';
import Map from '../images/map.webp';

function Location() {
    return (
        <><Navbar />
        <div className='about-header'>
            <h1 id='about-header'>Location</h1>
        </div>
        <div className='location'>
            <div className='location-paragraph'>
                <h1>Mobile Physical Therapy Servicing Tulsa, Oklahoma and surrounding areas</h1>
                <br />
                <h3>With Sozo Sports Physical Therapy, patients have the option to receive treatment in the comfort, familiarity, and privacy of their own homes or work spaces. With greater flexibility, Sozo Sports Physical Therapy comes to you: It’s physical therapy delivered.
                </h3>
            </div>
        </div>
        <div>
            <img id='map' src={Map} alt="" />
        </div>
        <div className='location'>
            <div className='location-paragraph2'>
                <h1>Servicing Tulsa, OK and surrounding areas:</h1>
                <li>Bixby</li>
                <li>Broken Arrow</li>
                <li>Collinsville</li>
                <li>Catoosa</li>
                <li>Claremore</li>
                <li>Coweta</li>
                <li>Glenpool</li>
                <li>Jenks</li>
                <li>Mounds</li>
                <li>Okmulgee</li>
                <li>Owasso</li>
                <li>Sand Springs</li>
                <li>Sapulpa</li>
                <li>Skiatook</li>
            </div>
        </div>
        <Footer /></>
    )
}


export default Location;