import './contact.css';
import Navbar from '../navbar';
import Footer from '../footer';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

// make sure to install emailjs browser by doing npm i '@emailjs/browser'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

function Contact() {
    const navigate = useNavigate();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs.sendForm('service_9aawwym', 'template_sx5hqwo', form.current, 'xNTEorSm-7VprqR12')

      .then((result) => {
          console.log(result.text);
          navigate ('/contact_reply');
      }, (error) => {
          console.log(error.text);
      });
  };

//   const virtual = (e) => {
//     e.preventDefault();
//     navigate ('/virtual_visit');
//   };

    const [yesChecked, setYesChecked] = useState(false);
    const [yesChecked2, setYesChecked2] = useState(false)
    const [noChecked, setNoChecked] = useState(false);
    const [ee, setEe] = useState()
    const [ff, setFf] = useState()
    const [fff, setFff] = useState()
    const [cc, setCc] = useState()
    const [unhide, setUnhide] = useState('virtual-dashboard-hidden')

    const [open, setOpen] = React.useState(false);

    const [open2, setOpen2] = React.useState(false);

    const [open3, setOpen3] = React.useState(false);

    const [open4, setOpen4] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleClose3 = () => {
        setOpen3(false);
    };

    const handleClickOpen4 = () => {
        setOpen4(true);
    };

    const handleClose4 = () => {
        setOpen4(false);
    };

    
  
    const handleYesChange = () => {
      setYesChecked(!yesChecked);
      if (!yesChecked) {
        setEe();
        setFf();
        setFff();
        setCc();
        setNoChecked(false); // Uncheck 'No' if 'Yes' is checked
        setUnhide('virtual-dashboard')
      }
    };
  
    const handleNoChange = () => {
      setNoChecked(!noChecked);
      if (!noChecked) {
        setYesChecked2(false);
        setYesChecked(false); // Uncheck 'Yes' if 'No' is checked
        setEe(false);
        setFf(false);
        setFff(false);
        setCc(false);
        setUnhide('virtual-dashboard-hidden')
      }
    };

    return (
        <><Navbar />
        <form ref={form} onSubmit={sendEmail}>
        <div className='about-header'>
            <h1 id='about-header'>Contact</h1>
        </div>
        <div className='flex-spacing-v'>
            <h1>In-person and virtual appointments available:</h1>
            <h3>Request an appointment via secure email, please complete the following form below.</h3>
            {/* <br />
            <hr />
            <br />
            <div className='flex-spacing'>
                <h3>Or schedule a</h3><button onClick={virtual}>virtural visit</button>
            </div> */}
        </div>
        <div className='contact-page'>
            <div>
                <h1 id='contact-input' >Name</h1>
                <div className='input-boxes'>
                <input name='name' type="text" />
                </div>  
            </div>
            <br />
            <div>
                <h1 id='contact-input' >Email</h1>
                <input name='email' type="text" />
            </div>
            <br />
            <div>
                <h1 id='contact-input' >Phone</h1>
                <input name='phone' type="text" />
            </div>
            <div className='virtual'>
                <h1>Make it a virtual visit?</h1>
                <div className='virtual-child'>
                    <label>
                        <h3>Yes</h3>
                        <input
                        id='check'
                        type="checkbox"
                        checked={yesChecked}
                        onChange={handleYesChange}
                        name='yes'
                        // disabled={noChecked} // Disable 'Yes' if 'No' is checked
                        />
                    </label>
                    <br />
                    <label>
                        <h3>No</h3>
                        <input
                        id='check'
                        type="checkbox"
                        checked={noChecked}
                        onChange={handleNoChange}
                        name='no'
                        // disabled={yesChecked} // Disable 'No' if 'Yes' is checked
                        />
                    </label>
                </div>
                <div className={unhide}>
                    <br />
                    <h3>Here are some options for a virtual appoinment, choose the one that best suits your needs:</h3>
                    <br />
                    <div className='virtual-options-contanier'>
                        <div className='virtual-options'>
                            <div className='virtual-options2'>
                                <h2>Evaluation</h2><input id='virtual-option-check' checked={ee} type="checkbox" name='evaluation'/>
                            </div>
                            <div className='virtual-options3'>
                                <h3>$150 : 55min</h3>
                                <React.Fragment>
                                    <h3 id='more-info' onClick={handleClickOpen}>More info</h3>
                                    <BootstrapDialog
                                        onClose={handleClose}
                                        aria-labelledby="customized-dialog-title"
                                        open={open}
                                    >
                                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                        Evaluation
                                        </DialogTitle>
                                        <IconButton
                                        aria-label="close"
                                        id='icon-button'
                                        onClick={handleClose}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                        >
                                        <CloseIcon />
                                        </IconButton>
                                        <DialogContent dividers>
                                        <Typography gutterBottom>
                                        During your first live video evaluation, it will begin with getting a thorough background of your medical history to find out more about the uniqueness of your symptoms. Since it is a telehealth visit, the assessment will be performed through analysis of your specific movement and task. Through observation, your physical therapist is trained to see break down in functional movement patterns. After finding your PT diagnosis, your physical therapist will prescribe high quality education, exercises, activity modification, biomechanics training, and/or pain management techniques to directly address impairments. Even without exercise equipment, your PT can develop a comprehensive program that creatively finds options and strategies to challenge you at home.
                                        </Typography>
                                        </DialogContent>
                                    </BootstrapDialog>
                                </React.Fragment>
                            </div>
                        </div>
                        <div className='virtual-options'>
                            <div className='virtual-options2'>
                                <h2>Follow-Up</h2><input id='virtual-option-check' checked={ff} type="checkbox" name='followup1'/>
                            </div>
                            <div className='virtual-options3'>
                                <h3>$125 : 55min</h3>
                                <React.Fragment>
                                    <h3 id='more-info' onClick={handleClickOpen2}>More info</h3>
                                    <BootstrapDialog
                                        onClose={handleClose2}
                                        aria-labelledby="customized-dialog-title"
                                        open={open2}
                                    >
                                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                        Follow-Up
                                        </DialogTitle>
                                        <IconButton
                                        aria-label="close"
                                        id='icon-button'
                                        onClick={handleClose2}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                        >
                                        <CloseIcon />
                                        </IconButton>
                                        <DialogContent dividers>
                                        <Typography gutterBottom>
                                        These appointments are helpful to actively monitor and progress towards your goals. Your 
PT can modify your exercise routine or give you strategies to improve and manage pain. We can also modify your posture and ergonomics to find a routine that best fits your needs. These video visits are beneficial if there is a barrier to seeing you in person.
                                        </Typography>
                                        </DialogContent>
                                    </BootstrapDialog>
                                </React.Fragment>
                            </div>
                        </div>
                        <div className='virtual-options'>
                            <div className='virtual-options2'>
                                <h2>Follow-Up</h2><input id='virtual-option-check' checked={fff} type="checkbox" name='followup2'/>
                            </div>
                            <div className='virtual-options3'>
                                <h3>$90 : 30min</h3>
                                <React.Fragment>
                                    <h3 id='more-info' onClick={handleClickOpen3}>More info</h3>
                                    <BootstrapDialog
                                        onClose={handleClose3}
                                        aria-labelledby="customized-dialog-title"
                                        open={open3}
                                    >
                                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                        Follow-Up
                                        </DialogTitle>
                                        <IconButton
                                        aria-label="close"
                                        id='icon-button'
                                        onClick={handleClose3}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                        >
                                        <CloseIcon />
                                        </IconButton>
                                        <DialogContent dividers>
                                        <Typography gutterBottom>
                                        These appointments are helpful to actively monitor and progress towards your goals. Your 
PT can modify your exercise routine or give you strategies to improve and manage pain. We can also modify your posture and ergonomics to find a routine that best fits your needs. These video visits are beneficial if there is a barrier to seeing you in person.
                                        </Typography>
                                        </DialogContent>
                                    </BootstrapDialog>
                                </React.Fragment>
                            </div>
                        </div>
                        <div className='virtual-options'>
                            <div className='virtual-options2'>
                                <h2>Consultation/Coaching</h2><input id='virtual-option-check' checked={cc} type="checkbox" name='coaching'/>
                            </div>
                            <div className='virtual-options3'>
                                <h3>$90 : 30min</h3>
                                <React.Fragment>
                                    <h3 id='more-info' onClick={handleClickOpen4}>More info</h3>
                                    <BootstrapDialog
                                        onClose={handleClose4}
                                        aria-labelledby="customized-dialog-title"
                                        open={open4}
                                    >
                                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                        Consultation/Coaching
                                        </DialogTitle>
                                        <IconButton
                                        aria-label="close"
                                        id='icon-button'
                                        onClick={handleClose4}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                        >
                                        <CloseIcon />
                                        </IconButton>
                                        <DialogContent dividers>
                                        <Typography gutterBottom>
                                        Personalized coaching to deliver a unique approach to injury prevention. This can be injury prevention from your everyday job task that puts stress on the body to wanting to work out safely to sports performance. We will discuss your specific needs and find the best way to meet your goal.
                                        </Typography>
                                        </DialogContent>
                                    </BootstrapDialog>
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h1 id='contact-input' >Message</h1>
                <h3>(In a few words describe your condition)</h3>
                <br />
                {/* <input name='message' type="text" /> */}
                <textarea id='contact-input2' maxlength="300" rows='4' type="text" name='message' label="Message" />
            </div>
            <br />
            <div className='contact_buttons'>
                <button id='button1'>Submit</button>
                {/* <a href="mailto:Tricia@sozosportspt.com" id='virtual_consult'>Schedule a virtual consult</a> */}
            </div>
            {/* <div>
                <h1>Phone</h1>
                <h3>314-791-5652</h3>
            </div>
            <br />
            <div>
                <h1>Hours</h1>
                <h3>Monday - Friday 8AM to 6 PM</h3>
                <h3>Saturday: By Appointment Only</h3>
            </div> */}
        </div>
        </form>
        <Footer/>
        </>
    )
}


export default Contact;