import './App.css';
import Footer from './footer';
import { Link } from 'react-router-dom';
import Navbar from './navbar';
import Care from './images/care.webp'
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import React, { useState, useRef } from 'react';
import first from './images/pelvic.webp';
import second from './images/sport2.webp';
import Services from './pages/services';
import Contact from './pages/contact';
import Carousel from './component/Img_Carousel';
import reviews from './data/data.json';

function App() {

  return (
    <>
    <body>
        <div>
          <div className='top-headline'>
            <Navbar id='navbar3'/>
            <div className='top-headline-paragraph'>
              <h1 id='top-headline-paragraph'>Concierge Mobile Physical Therapy</h1>
              <h1 id='top-headline-paragraph2'><i>that bridges the gap for orthopedic and pelvic health</i></h1>
              <br />
              <Link to='/contact' element={<Contact/>}><button id="hero-button">Schedule Today</button></Link>
            </div>
            
          </div>
          <div className='mid-section'>
            <div className='body1'>
                <div className='first'>
                    <img id='first' src={first}></img>
                </div>
                <div className='first-words'>
                    <h1 id='body1-words'>Specialized care to improve your quality of life</h1>
                    <h3 id='body1-words2'>By working with a trained therapist, individuals experiencing pelvic floor dysfunction, such as incontinence, pelvic pain, or prolapse, can benefit from personalized exercises, manual techniques, and lifestyle modifications aimed at alleviating symptoms and restoring optimal pelvic health.</h3>
                    <a href='/services' id='learnmore-button'>Learn More</a>              
                </div>
            </div>
            <div className='body2'>
                <div className='second-words'>
                    <h1 id='body1-words'>Goodbye Pain</h1>
                    <h3 id='body1-words2'>Orthopedic and sports physical therapy utilizes exercises, manual techniques, and targeted rehabilitation strategies to restore function, mobility, and strength in individuals recovering from sports injuries, post-surgery, or orthopedic conditions like fractures, sprains, or arthritis.</h3>
                    <a href='services#services-section' id='learnmore-button'>Learn More</a>                  
                </div>
                <div className='second'>
                    <img id='second' src={second}></img>
                </div>
            </div>
        </div>
          <div className='bottom-headline'>
            <div className='bottom-headline-words'>
              <img id='care' src={Care} alt="" />
            </div>
            <div className='bottom-headline-words'>
              <br />
              <h1>APPROACH TO CARE</h1>
              <br />
              <h3>The greek word “Sōzō” means “to be made whole, healed, restored.” It is used in the bible over 100 times in regards to holistic healing. Often times physical healing cannot be fully achieved until we uncover the roots that keep one from living a life of freedom and wellness. </h3>
              <br />
              <h3>At Sozo Sports Physical Therapy, LLC you’ll get one-on-one, 60 minute sessions that are specialized in manual techniques. The exercises will be focused on quality movement strategies and will integrate the pelvic floor into orthopedics. This is a gap in treatment you are likely missing elsewhere. We will work together as a team to get you better with as few sessions as possible. At Sozo Sports Physical Therapy the goal is to leave you whole, healed, and restored!</h3>
            </div>
          </div>
          <div class="custom-shape-divider-bottom-1694709272">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
              </svg>
          </div>
          <div className='reviews-bottom-page'>
            <div className='what-people-saying'>
                <h1 id="what">What People Are Saying</h1>
            </div>
            <div className='carousel'>
              <Carousel data={reviews}/>
            </div>
            <div className='more-reviews'>
              <a id='more-reviews' href='https://www.google.com/maps/place/Sozo+Sports+Physical+Therapy/@36.1462025,-96.0242659,11z/data=!4m6!3m5!1s0x2c98e8696dbdac67:0xae48438c8f92d96f!8m2!3d36.1523015!4d-95.8780104!16s%2Fg%2F11s5fvyc_6?entry=ttu' target="_blank">Even More Reviews</a>
              <IoIosArrowForward id='forward-arrow-2'/>
            </div>
          </div>
          <Footer />
        </div>
    </body>    
    </>
  );
}

export default App;
