import './services.css';
import Navbar from '../navbar';
import Footer from '../footer';
import service_pic from '../images/services.webp';
import Neck from '../images/neck.webp';


function Services() {
    return (
        <><Navbar />
        <div className='about-header'>
            <h1 id='about-header'>Services</h1>
        </div>
        <div className='services'>
            <div className='services-pic'>
                <img src={service_pic} id="services-pic" alt="" />
            </div>
            <br />
            <div>
                <h1>PELVIC FLOOR PHYSICAL THERAPY AND REHABILITATION</h1>
                <br />
                <h3>Pelvic floor dysfunction is common but many do not seek treatment because they are unaware it is treatable. Just because something is common does not mean it’s normal. Most women and men suffer in silence or believe they just have to "deal with" this issue. Unfortunately, the majority of healthcare providers are not given much training in the treatment of pelvic dysfunction. </h3>
                <br />
                <h3>Pelvic floor physical therapy involves the strengthening, flexibility, coordination of the pelvic floor muscles with associated structures. These can be injured from prior surgery or injuries, childbirth, sports, chronic postures and movement issues from repetitive work or daily tasks. The pelvic floor is responsible for supporting pelvic organs, maintaining urinary continence and sexual function; it also helps to stabilize the body. Sozo Sports Physical Therapy helps to provide a comprehensive treatment plan after assessing the body as a whole including areas associated with the pelvis. Along with innovative techniques based on latest research, Sozo Sports Physical Therapy helps you to achieve the quality life you deserve.</h3>
            </div>
            <br />
            <div><h1>You may be referred for a variety of reasons including:</h1>
            </div>
            <div>
                <li id='list'>Pain in the pelvis, low back, or groin
                </li><li id='list'>Frequent urination and/or overactive bladder 
                </li><li id='list'>Urinary/fecal incontinence
                </li><li id='list'>Pelvic organ prolapse
                </li><li id='list'>Pain or discomfort during intercourse
                </li><li id='list'>Constipation
                </li><li id='list'>Urinary retention
                </li><li id='list'>Diastasis recti
                </li><li id='list'>Pre/Post-partum care
                </li><li id='list'>Pudendal neuralgia
                </li><li id='list'>Post-prostatectomy
                </li><li id='list'>Prostatitis
                </li>
                </div>
                <br />
            
                <h1>ORTHOPEDIC AND SPORTS REHABILITATION</h1>
                <br />
                <h3>Musculoskeletal injuries can affect your daily life and impact your ability to do things you enjoy. Sozo Sports Physical Therapy designs an individualized program to help with recovery to impairments related to the joints, muscles, bones, and nerves. Sozo Sports PT is specialized in manual techniques that can help target those issues. </h3>
                <h3>Did you know pelvic floor dysfunction is an often overlooked component of orthopedic injuries? With special training in this area Sozo Sports PT has the toolset to give you care that is comprehensive and complete. The goal is to reduce pain, restore function, and get you back to your baseline.   </h3>
                <h3>Leave pain behind and get back in your game!</h3>
                <br />
            <div className='services-bottom' id='services-section'>
                <div className='services-equipment'>
                    <h1>Areas of Specialty:</h1>
                    <li id='list'>Back (Low back pain, thoracic, and neck pain)
                    </li><li id='list'>Pelvis, groin, and sacroiliac joint pain
                    </li><li id='list'>Hip pathology (IT band, labrum, impingement)
                    </li><li id='list'>Rib and abdominal pain
                    </li><li id='list'>Shoulder pain (impingement, tears, labrum)
                    </li><li id='list'>Elbow (tennis elbow, golfer’s elbow)
                    </li><li id='list'>Knee (ACL/PCL, Ligamentous, Meniscus, post-surgical, patellar/quad tendon pain)
                    </li><li id='list'>Foot and ankle
                    </li>
                </div>
                <div className='neck-img'>
                    <img src={Neck} alt="neck" id="neck"/>
                </div>
            </div>
            
        </div>
        <Footer /></>
    )
}


export default Services;