import './contact_reply.css';
import Navbar from '../navbar';
import Footer from '../footer';


function Contact_Reply () {
    return (
        <><Navbar />
        <div className='about-header'>
            <h1 id='about-header'>Thank You!</h1>
        </div>
        <div className='contact-page'>
            <div>
                <h1 id='contact-input'>We will get back to you as soon as we can!</h1>
                <h3></h3>
                </div>
                
            </div>
            <br />
            <br />
            <br />
            <br />
        
        </>
    )
}


export default Contact_Reply;