import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    // icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'About',
    path: '/about',
    // icon: <FaIcons.FaQuestion />,
    cName: 'nav-text'
  },
  {
    title: 'Services',
    path: '/services',
    // icon: <FaIcons.FaQuestion />,
    cName: 'nav-text'
  },
  {
    title: 'Location',
    path: '/location',
    cName: 'nav-text'
  },
  {
    title: 'Contact',
    path: '/contact',
    // icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
  
  
  {
    title: 'FAQ',
    path: '/faq',
    // icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text'
  },
//   {
//     title: 'Appointments',
//     path: '/appointments',
//     // icon: <FaIcons.FaEnvelopeOpenText />,
//     cName: 'nav-text'
//   }
];